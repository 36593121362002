import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import seedgreenAPI from "@/lib/seedgreen-api";
import store from "@/store";

export default {
	get: function (plantingId: number) {
		// Enqueue API-related mutations
		OfflineQueue.add(
			"headSizeColorMap.get",
			plantingId,
			function duplicateMerger(queueItem) {
				if (
					queueItem.endpoint === "headSizeColorMap.get" &&
					queueItem.payload === plantingId
				)
					return -1; // Favor the item already in the queue
			},
		);
	},
	_get: function (plantingId: number) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.get("LotDetails/GetColorMapAndSizingGuide", {
							data: {
								lotId: plantingId,
							},
						})
						.then((response) => response.data)
						.then(function (result) {
							let colorMap = null;
							let sizingGuide = null;

							if (result) {
								if (Object.keys(result.colorMap).length)
									colorMap = result.colorMap;

								if (Object.keys(result.sizingGuide).length)
									sizingGuide = result.sizingGuide;
							}

							store.dispatch("setHeadSizeColorMap", {
								plantingId: plantingId,
								colorMap: {
									time: new Date().getTime(),
									colorMap: colorMap,
								},
								sizingGuide: {
									time: new Date().getTime(),
									sizingGuide: sizingGuide,
								},
							});

							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};
