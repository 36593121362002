import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import seedgreenAPI from "@/lib/seedgreen-api";
import store from "@/store";
import { dateFormat } from "@/utils";

export async function downloadExport(
	date: Date,
	type: "estimates" | "packout",
	seasonCode: "FC" | "SC",
) {
	let url;

	if (type === "estimates") {
		url = `HarvestOverview/Export?date=${dateFormat(date, "yyyy-MM-ddZ", false)}`;
	} else if (type === "packout") {
		url = `v2/HarvestOverview/PackoutExport?date=${dateFormat(date, "yyyy-MM-ddZ", false)}&seasonCode=${seasonCode}`;
	} else {
		throw new Error("Invalid type specified.");
	}

	const response = await seedgreenAPI(url, {
		responseType: "blob",
	});

	// Extract filename from content-disposition header. Default to "download.xlsx" if not present.
	const contentDisposition = response.headers["content-disposition"];
	let filename = "download.xlsx";

	if (contentDisposition) {
		const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
		if (filenameMatch) {
			filename = filenameMatch[1];
		}
	}

	return {
		filename: filename,
		data: response.data,
	};
}

export default {
	createRanchYield: function (value: {
		activity: never[];
		tempId: string | undefined;
	}) {
		console.debug("createRanchYield", JSON.stringify(value));
		value.activity = value.activity || [];
		value.tempId = window.URL.createObjectURL(new Blob([]))
			.split("/")
			.pop();
		const payload = {
			value,
			valueType: "ranchYield",
		};

		// Update local state
		store.dispatch("createRanchYield", payload);

		OfflineQueue.add("harvestOverview.createRanchYield", payload);
	},
	_createRanchYield: function (payload: { value: unknown }) {
		console.debug("_createRanchYield", JSON.stringify(payload));
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("RanchYieldStatistic", payload.value)
						.then(() => {
							console.debug(
								"_createRanchYield.done",
								JSON.stringify(payload),
							);
							store.dispatch("commitRanchYield", payload.value);
							resolve(payload.value);
						})
						.catch(reject);
				})
				.catch(reject);
		});
	},
};
