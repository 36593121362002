/**
 * NOTE: Deprecated!!
 * Use vue-query usePutITask() mutation instead. Old pages need this because they are in
 * options API.
 */
import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import { Task } from "seedgreen-shared/models/Task";
import store from "@/store";
import seedgreenAPI from "@/lib/seedgreen-api";

type TaskPayload = {
	value: Task;
	vid: string;
};

export default {
	put: function (task: Task) {
		console.debug("api.tasks.put", task);

		const payload: TaskPayload = {
			value: task,
			vid:
				task.virtualId ??
				`${task.location?.plantingIds?.toString()}:${task.plannedDate}`,
		};

		OfflineQueue.add(
			"tasks.put",
			payload,
			function duplicateMerger(queueItem) {
				if (
					queueItem.endpoint === "tasks.put" &&
					(queueItem.payload.value.id === payload.value.id ||
						queueItem.payload.vid === payload.vid)
				)
					return 1;
			},
		);

		return store.dispatch("putTask", task);
	},
	_put: async function (payload: TaskPayload) {
		console.debug("api.tasks._put", payload);
		await ensureValidToken();

		const { data } = await seedgreenAPI.put("/Task", payload.value);

		data.virtualId = payload.vid;
		return store.dispatch("putTask", data);
	},
};
