import {
	OfflineMutationQueueOptions,
	useOfflineMutationQueue,
} from "@/stores/offlineMutationQueue";
import { setLocaleOptions } from "seedgreen-shared/query/user";
import { MutationOptions, UseMutationReturnType } from "@tanstack/vue-query";
import {
	DuplicateResult,
	type DuplicateMerger,
} from "seedgreen-shared/types/query.types";
import { mobileQueryClient } from "./query-client";
import { captureException } from "@sentry/vue";
import {
	MutateITaskData,
	putITaskOptions,
} from "seedgreen-shared/query/itasks";
import { ITask } from "seedgreen-shared/models/ITask";

/**
 * ======================================================================================
 *
 * Purpose of this module:
 *
 * vue-query does not have the ability to fully persist mutations when the device is
 * offline. It will hold on to them as long as the app is loaded in memory, but once
 * the app is closed the mutations are lost. This module provides a wrapper that adds
 * this functionality - it will store mutations in localStorage if the device is
 * offline, and try to re-apply them the next time the app is opened.
 *
 * (the react query version appears to have this functionality built in, so if we
 * switch to react we can potentially ditch this)
 *
 * ======================================================================================
 */

/**
 * Wrapper for a vue-query mutation that has offline persistence. Returned mutation will
 * function as normal if the device is online, but if the device is offline, the mutation
 * will be saved to localStorage and retried again once the device is online.
 * @param mutationKey Key in MUTATIONS_MAP. Add a new key for new mutations.
 * @param mutation The vue-query mutation function
 * @param offlineOptions Optional - override default offline options for this mutation
 * @param map_override Only used for testing
 * @returns
 */
export function usePersistedMutation(
	mutationKey: keyof typeof MUTATIONS_MAP,
	mutation: UseMutationReturnType<any, any, any, any>,
	offlineOptions?: OfflineMutationQueueOptions,
	map_override?: MutationsMap,
) {
	const offlineQueue = useOfflineMutationQueue();
	const mappedOptions =
		map_override?.[mutationKey] ?? MUTATIONS_MAP[mutationKey];

	offlineOptions = { ...offlineOptions, ...mappedOptions?.offlineOptions };

	/**
	 * Perform a mutation that will be persisted to the offline queue if the client is offline.
	 *
	 * (as with normal mutate functions, this cannot be used if mutationFn is async)
	 * @param variables
	 */
	function mutate(variables: any) {
		if (navigator.onLine) {
			// If we're online, just call it right away
			mutation.mutate(variables);
		} else {
			offlineQueue.add(mutationKey, variables, offlineOptions);

			// Pre-emptively apply optimistic updates while waiting for network connection
			if (mappedOptions.mutationOptions.onMutate) {
				let context;
				try {
					context = mappedOptions.mutationOptions.onMutate(variables);
				} catch (error) {
					captureException(error);
					if (mappedOptions.mutationOptions.onError)
						mappedOptions.mutationOptions.onError(
							error,
							variables,
							context,
						);
				}
			}
		}
	}

	/**
	 * Perform a mutation that will be persisted to the offline queue if the client is offline.
	 * @param variables
	 */
	async function mutateAsync(variables: any) {
		if (navigator.onLine) {
			// If we're online, just call it right away
			await mutation.mutateAsync(variables);
		} else {
			offlineQueue.add(mutationKey, variables, offlineOptions);

			// Pre-emptively apply optimistic updates while waiting for network connection
			if (mappedOptions.mutationOptions.onMutate) {
				let context;
				try {
					context = mappedOptions.mutationOptions.onMutate(variables);
				} catch (error) {
					captureException(error);
					if (mappedOptions.mutationOptions.onError)
						mappedOptions.mutationOptions.onError(
							error,
							variables,
							context,
						);
				}
			}
		}
	}

	return {
		...mutation,
		mutate,
		mutateAsync,
	};
}

/**
 * Custom map of keys to mutation function options. Any mutations that are desired to have
 * offline persistence should be adeed here.
 *
 * (This is necessary because when the device goes back online, it is not guaranteed that
 * the original mutations are still mounted. Mutation functions cannot be persisted to
 * localStorage or IndexedDB, so the client will need to rebuild the mutation objects manually.
 * This map allows the queryClient to grab the original mutation functions after the app
 * is restarted.)
 */
type MutationsMap = Record<
	string,
	{
		mutationOptions: MutationOptions<any, any, any, any>;
		offlineOptions: OfflineMutationQueueOptions<any>;
	}
>;
export const MUTATIONS_MAP: MutationsMap = {
	"user.setLocale": {
		mutationOptions: setLocaleOptions(mobileQueryClient),
		offlineOptions: {
			duplicateMerger: () => DuplicateResult.KeepIncoming,
			expiresMs: 24 * 60 * 60 * 1000,
		},
	},
	"itask.put": {
		mutationOptions: putITaskOptions(mobileQueryClient, false),
		offlineOptions: {
			duplicateMerger: (incoming, existing) => {
				if (incoming.task.id === existing.task.id)
					return DuplicateResult.KeepIncoming;
				return DuplicateResult.KeepBoth;
			},
			expiresMs: 5 * 60 * 1000,
		} as OfflineMutationQueueOptions<MutateITaskData>,
	},
};
