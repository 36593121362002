import {
	experimental_createPersister,
	PERSISTER_KEY_PREFIX,
} from "@tanstack/query-persist-client-core";
import {
	hashKey,
	QueryClient,
	QueryKey,
	SetDataOptions,
	Updater,
} from "@tanstack/vue-query";
import { MaybeRefDeep } from "@tanstack/vue-query/build/legacy/types";
import { newIdbAsyncStorage } from "./idb-storage";
import { captureException } from "@sentry/vue";
import _ from "lodash";

/**
 * This is the mobile query client. It is a custom vue-query client that uses the persistence
 * wrapper around queryFn that saves the result of a query to IndexedDB. This file also modifies
 * the `setQueryData` function so that it will update the persisted data in IndexedDB properly,
 * since vue-query doesn't support this yet (keep an eye out for this in future versions).
 */

const QUERY_DEFAULT_MEMORY_GC_TIME = 5 * 60 * 1000; // 5 minutes
const MAX_IDB_PERSISTED_AGE = 3 * 24 * 60 * 60 * 1000; // 3 days

export const idbStorage = newIdbAsyncStorage("db", "query-client");

/**
 * Queries that should *not* be persisted to IndexedDB
 */
const queryKeysToSkipPersist: Set<ReturnType<typeof hashKey>> = new Set(
	[["verify-token"]].map(hashKey),
);

/**
 * Custom query client for mobile that handles persisted data
 */
export const mobileQueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			gcTime: QUERY_DEFAULT_MEMORY_GC_TIME,
			persister: experimental_createPersister({
				storage: idbStorage,
				maxAge: MAX_IDB_PERSISTED_AGE,
				serialize: (persistedQuery) => persistedQuery, // We don't need to json serialize since we're using IDB
				deserialize: (cachedData) => cachedData,
				filters: {
					predicate: (query) =>
						!queryKeysToSkipPersist.has(hashKey(query.queryKey)),
				},
			}),
		},
	},
});

/**
 * We replace the default `setQueryData` of the query client with our own that also
 * persists data to IndexedDB.
 *
 * This version is sync and does not wait for IDB to update, since the original
 * function is also sync.
 * @param queryKey Key of query
 * @param updater Data update function, or new data
 */
const _setQueryData = mobileQueryClient.setQueryData.bind(mobileQueryClient);
mobileQueryClient.setQueryData = (
	queryKey: QueryKey,
	updater: Updater<any, any>,
	options?: MaybeRefDeep<SetDataOptions>,
) => {
	let data: any;

	if (_.isFunction(updater)) {
		// Grab current data from queryClient (generally more accurate than IndexedDB)
		const cacheData = mobileQueryClient.getQueryData(queryKey);

		try {
			data = updater(cacheData);
		} catch (e) {
			captureException(e);
			throw e;
		}
	} else {
		data = updater;
	}

	// Call the original setQueryData function
	_setQueryData(queryKey, data, options);

	// Trigger IDB update
	(async () => {
		await setQueryDataPersisted(queryKey, _.cloneDeep(data));
	})();
};

/**
 * Get the storage key for a query that vue-query will use in the persistence wrapper
 */
export function getQueryStorageKey(key: QueryKey) {
	return `${PERSISTER_KEY_PREFIX}-${hashKey(key)}`;
}

/**
 * Persist query data to IndexedDB
 */
async function setQueryDataPersisted(key: QueryKey, data: any) {
	const storageKey = getQueryStorageKey(key);
	// Update persisted data before updating cache
	const storedQuery = await idbStorage.getItem(storageKey);

	if (storedQuery) {
		try {
			if (data) {
				storedQuery.state.data = data;
				await idbStorage.setItem(storageKey, storedQuery);
			} else {
				await idbStorage.removeItem(storageKey);
			}
		} catch (error) {
			captureException(error);
			// If we can't persist for some reason, clear the persisted data so it's invalid and must be refetched
			await idbStorage.removeItem(storageKey);
			throw error;
		}
	}
}
