import apis from "@/api/apis";
import store from "@/store";
import * as Sentry from "@sentry/vue";

// Does an error warrant requeuing? Right now, the only instance that does is if the connection is lost mid-request
function shouldRequeue(e: any) {
	// ERR_CONNECTION_REFUSED
	return e && e.status === 0;
}

function addToOfflineQueue(
	endpoint: keyof typeof apis,
	payload?: any,
	duplicateMerger?: (a: any, b: any) => any,
) {
	// Can we just call it instead of queueing it?
	if (navigator.onLine) {
		(apis[endpoint as keyof typeof apis] as (payload: any) => Promise<any>)(
			payload,
		).catch((e) => {
			if (shouldRequeue(e)) {
				// Add to queue if connectivity is lost
				store.dispatch("addToOfflineQueue", {
					endpoint: endpoint,
					payload: payload,
					duplicateMerger: duplicateMerger,
				});
			} else {
				// TODO: Otherwise, add a resync event to the offline queue?
				Sentry.captureException(e);
			}
		});
	}
	// Queue it
	else {
		store.dispatch("addToOfflineQueue", {
			endpoint: endpoint,
			payload: payload,
			duplicateMerger: duplicateMerger,
		});
	}
}

/**
 * Pop an item off of the offline queue
 * @param store Store reference
 * @returns
 */
function getFromOfflineQueue(store: any) {
	const result = store.state.offlineQueue[0];
	store.dispatch("removeFromOfflineQueue");
	return result;
}

function processOfflineQueue(store: any) {
	if (navigator.onLine && store.state.offlineQueue.length) {
		const nextItem = getFromOfflineQueue(store);

		(
			apis[nextItem.endpoint as keyof typeof apis] as (
				payload: any,
			) => Promise<any>
		)(nextItem.payload)
			.then(() => {
				processOfflineQueue(store);
			})
			.catch((e) => {
				if (shouldRequeue(e)) {
					addToOfflineQueue(nextItem.endpoint, nextItem.payload);
				} else {
					Sentry.captureException(e);
					// TODO: Otherwise, add a resync event to the offline queue?
				}
			});
	}
}

const vuexPlugin = (store: any) => {
	store.subscribe((mutation: any, _state: any) => {
		// If we're just coming online, process the offline queue
		if (mutation.type === "setOnline" && mutation.payload === true) {
			processOfflineQueue(store);
		}
	});
};

export default {
	add: addToOfflineQueue,
	process: processOfflineQueue,
	plugin: vuexPlugin,
};
