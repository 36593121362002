import axios from "axios";

export const SEEDGREEN_BASE_URL =
	(process.env.NODE_ENV === "test" && "http://seedgreen.gva.test") ||
	process.env.VUE_APP_SEEDGREEN_API_BASE_URL;

if (!SEEDGREEN_BASE_URL)
	console.error("SEEDGREEN_BASE_URL environment variable is not defined");

const seedgreenAPI = axios.create({
	baseURL: SEEDGREEN_BASE_URL + "/API",
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
	},
});

export default seedgreenAPI;
