import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import store from "@/store";
import seedgreenAPI from "@/lib/seedgreen-api";

export default {
	update: function (plantingId: number, value: any) {
		const payload = {
			plantingId,
			value,
			valueType: "weight",
		};

		// Update local state
		store.dispatch("updateActualStatistic", payload);

		// Enqueue API-related mutations
		OfflineQueue.add(
			"bulkWeight.update",
			payload,
			function duplicateMerger(queueItem) {
				// Unqueue actions that are rendered obsolete by the new action
				if (
					queueItem.endpoint === "bulkWeight.update" &&
					queueItem.payload.plantingId === payload.plantingId
				)
					return 1;
			},
		);
	},
	_update: function (payload: { plantingId: string; value: any }) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put(
							`LotDetails/UpdateStatistic/${payload.plantingId}`,
							{
								Value: payload.value || -1, // -1 for null/blank values
								BinName: "weight",
								MeasureType: 9, //average head weight
								ContextType: 1, //actual
							},
						)
						.then(() => {
							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};
