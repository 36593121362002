import { modalController } from "@ionic/vue";
import { defineAsyncComponent } from "vue";

/**
 *
 * @param automatic Whether this modal was triggered by a user, or automatically
 * @returns
 */
export default async function showInstallPossibleModal(automatic = false) {
	const modal = await modalController.create({
		component: defineAsyncComponent(
			() => import("./InstallPossibleModal.vue"),
		),
		cssClass: "install-hint-modal auto-height",
		backdropDismiss: false,
		componentProps: { automatic },
	});
	return modal.present();
}
