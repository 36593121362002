<template>
	<ion-app>
		<ion-router-outlet />

		<!-- Side menu -->
		<ion-menu
			side="end"
			type="overlay"
			ref="menu"
			content-id="menu-content"
			swipe-gesture="false"
			v-if="showSideMenu"
		>
			<ion-header>
				<ion-toolbar :color="store.state.online ? 'branding' : 'dark'">
					<div
						style="
							display: flex;
							flex-direction: column;
							align-items: center;
							padding-top: 8px;
						"
					>
						<div
							style="
								display: flex;
								gap: 4px;
								font-size: 1.1rem;
								font-weight: bold;
							"
						>
							<ion-icon :icon="icons.person"></ion-icon>
							{{ user?.name }}
						</div>
						<CustomerSelect />
					</div>
				</ion-toolbar>
			</ion-header>

			<ion-content id="menu-content">
				<ion-list style="pointer-events: auto" @click="closeMenu">
					<!-- Create Note -->
					<ion-item
						button
						@click="showNotePopup"
						v-if="
							permissionsSet?.has(Permission.EditAnyFieldNote) ||
							permissionsSet?.has(Permission.CreateFieldNotes)
						"
					>
						<ion-icon :icon="icons.create" slot="start"></ion-icon>
						<ion-label>{{ $t("note.create-note") }}</ion-label>
					</ion-item>

					<!-- Create task request -->
					<ion-item
						button
						@click="showTaskRequestPopup"
						v-if="permissionsSet?.has(Permission.CreateTaskRequest)"
					>
						<ion-icon
							:icon="icons.clipboard"
							slot="start"
						></ion-icon>
						<ion-label>{{ $t("task.task-request") }}</ion-label>
					</ion-item>

					<!-- Tasks -->
					<ion-item
						button
						:disable="
							$router.currentRoute.value.name
								?.toString()
								.startsWith('tasks.')
						"
						:color="
							$router.currentRoute.value.name
								?.toString()
								.startsWith('tasks.')
								? 'primary'
								: ''
						"
						@click="$router.push({ name: 'tasks.list' })"
					>
						<ion-icon
							:icon="icons.checkbox"
							slot="start"
						></ion-icon>
						<ion-label>{{ $tc("task.task", 2) }}</ion-label>
					</ion-item>

					<!-- Harvest Overview -->
					<ion-item
						button
						:color="
							$router.currentRoute.value.name
								?.toString()
								.startsWith('harvest.')
								? 'primary'
								: ''
						"
						@click="$router.push({ name: 'harvest.overview' })"
						v-if="
							permissionsSet?.has(
								Permission.ViewMobileHarvestOverview,
							)
						"
					>
						<ion-icon :icon="icons.leaf" slot="start"></ion-icon>
						<ion-label>{{
							$t("harvest-overview.harvest-overview")
						}}</ion-label>
					</ion-item>

					<!-- Harvest Schedule -->
					<ion-item
						button
						:color="
							$router.currentRoute.value.name ===
							'harvest-schedule'
								? 'primary'
								: ''
						"
						@click="$router.push({ name: 'harvest-schedule' })"
						v-if="permissionsSet?.has(Permission.ViewMobileHarvest)"
					>
						<ion-icon
							:icon="icons.calendar"
							slot="start"
						></ion-icon>
						<ion-label>{{
							$t("harvest-schedule.harvest-schedule")
						}}</ion-label>
					</ion-item>

					<!-- Lot Search -->
					<ion-item
						button
						:color="
							$router.currentRoute.value.name === 'lot-search'
								? 'primary'
								: ''
						"
						@click="$router.push({ name: 'lot-search' })"
						v-if="permissionsSet?.has(Permission.ViewMobileLots)"
					>
						<ion-icon :icon="icons.search" slot="start"></ion-icon>
						<ion-label>{{ $t("lot-search.lot-search") }}</ion-label>
					</ion-item>

					<!-- Irrigation -->
					<ion-item
						button
						:color="
							$router.currentRoute.value.name === 'irrigation'
								? 'primary'
								: ''
						"
						@click="$router.push({ name: 'irrigation' })"
						v-if="
							permissionsSet?.has(
								Permission.ViewIrrigationSchedules,
							) ||
							permissionsSet?.has(
								Permission.SetIrrigationSchedules,
							)
						"
					>
						<ion-icon :icon="icons.water" slot="start"></ion-icon>
						<ion-label>{{ $t("task.irrigation") }}</ion-label>
					</ion-item>

					<!-- Alerts -->
					<ion-item
						button
						:color="
							$router.currentRoute.value.name === 'alerts'
								? 'primary'
								: ''
						"
						@click="$router.push({ name: 'alerts' })"
					>
						<ion-icon
							:icon="icons.notifications"
							slot="start"
						></ion-icon>
						<ion-label>{{ $tc("common.alert", 2) }}</ion-label>
						<ion-badge
							color="danger"
							style="padding: 4px 6px"
							v-if="store.state.alerts.length"
						>
							{{ store.state.alerts.length }}
						</ion-badge>
					</ion-item>

					<!-- Register Device -->
					<ion-item
						v-if="!store.state.deviceRegistered"
						button
						@click="registerDevice"
					>
						<ion-icon
							:icon="icons.fingerPrint"
							slot="start"
						></ion-icon>
						<ion-label>{{ $t("menu.register-device") }}</ion-label>
					</ion-item>

					<!-- Desktop Switch -->
					<!-- FIXME: Why is this here, when the desktopRedirect method doesn't exist? -->
					<!-- <ion-item v-if="desktopSwitchEnabled" button @click="desktopRedirect">
						<ion-icon :icon="desktopicon" slot="start"></ion-icon>
						<ion-label>{{ $t("menu.visit-desktop") }}</ion-label>
					</ion-item> -->

					<!-- Logout -->
					<ion-item button @click="logout">
						<ion-icon :icon="icons.logOut" slot="start"></ion-icon>
						<ion-label>{{ $t("menu.logout") }}</ion-label>
					</ion-item>
				</ion-list>
			</ion-content>

			<ion-footer>
				<!-- Install -->
				<ion-item
					detail
					color="success"
					v-if="!isPWA"
					@click="_showInstallPossibleModal"
				>
					<ion-icon
						:icon="icons.downloadOutline"
						slot="start"
					></ion-icon>
					<ion-label>{{ $t("pwa.install-app") }}</ion-label>
				</ion-item>
				<!-- Language -->
				<ion-item>
					<ion-icon :icon="icons.earth" slot="start"></ion-icon>
					<ion-label>{{ $t("menu.language") }}:</ion-label>
					<ion-select
						v-model="vi18n_locale"
						@ionChange="saveLocale($event.target.value)"
						v-i18n
					>
						<ion-select-option
							v-for="locale in $i18n.availableLocales"
							:key="`locale-${locale}`"
							:value="locale"
							>{{ locale.toUpperCase() }}</ion-select-option
						>
					</ion-select>
				</ion-item>
			</ion-footer>
		</ion-menu>
	</ion-app>
	<vue-query-devtools />
</template>

<script setup lang="ts">
	import * as webauthn from "@/lib/webauthn";
	import {
		IonApp,
		IonRouterOutlet,
		IonContent,
		IonHeader,
		IonFooter,
		IonToolbar,
		IonIcon,
		IonLabel,
		IonItem,
		IonBadge,
		IonList,
		IonMenu,
		IonSelect,
		IonSelectOption,
		loadingController,
	} from "@ionic/vue";
	import { computed, getCurrentInstance, onMounted, ref, watch } from "vue";
	import * as icons from "ionicons/icons";
	import { sendMessage } from "@/components/send-message";
	import { logout } from "@/api/logout";
	import { showAddEditNote as showAddNote } from "@/views/Modal/AddEditNote.vue";
	import { showAddEditTaskRequest } from "@/views/Modal/AddEditTaskRequest.vue";
	import { isPWA } from "@/lib/pwa";
	import showInstallPossibleModal from "@/components/install/InstallPossibleModal";
	import { Task } from "seedgreen-shared/models/Task";
	import { useStore } from "vuex";
	import { useI18n } from "vue-i18n";
	import {
		useUser,
		usePermissions,
		useSetLocale,
	} from "seedgreen-shared/query/user";
	import { Permission } from "seedgreen-shared/models/Permission";
	import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
	import CustomerSelect from "./components/CustomerSelect.vue";
	import { type Locale } from "seedgreen-shared/types/common.types";
	import { usePersistedMutation } from "@/lib/persisted-mutation";
	import { enableOfflineMutations } from "@/stores/offlineMutationQueue";
	import { useVerifyToken } from "seedgreen-shared/query/verify-token";
	import * as Sentry from "@sentry/vue";

	const store = useStore();
	const { locale: vi18n_locale, t } = useI18n();
	const { user } = useUser();
	const { permissionsSet } = usePermissions();
	const { mutateAsync: setLocaleAsync } = usePersistedMutation(
		"user.setLocale",
		useSetLocale(),
	);

	defineProps({
		title: { type: String, default: "" },
		showBackButton: { type: Boolean, default: true },
		loginRequired: { type: Boolean, default: true },
		showSideMenu: { type: Boolean, default: true },
		refreshCallback: { type: Function, default: null },
	});

	onMounted(() => {
		enableOfflineMutations(useVerifyToken().tokenValid);
	});

	watch(user, () => {
		if (user.value?.locale && user.value.locale !== vi18n_locale.value) {
			console.debug(
				`User data overrode locale from ${vi18n_locale.value} to ${user.value.locale}`,
			);
			vi18n_locale.value = user.value.locale;
			localStorage.locale = user.value.locale;
		}
	});

	watch(user, () => {
		// this sets up the user data for Sentry when the user ref changes
		Sentry.setContext("User Data", user.value ?? {});
		Sentry.setUser({
			id: user.value?.id ?? "",
			username: user.value?.name ?? "",
			email: user.value?.userName ?? "",
			customerId: user.value?.customerId ?? "",
		});
	});

	async function saveLocale(locale: Locale) {
		if (!["en", "es"].includes(locale)) throw new Error("Invalid locale");

		localStorage.locale = locale;
		if (user.value?.locale !== locale)
			await setLocaleAsync(locale as Locale);
	}

	const menu = ref<InstanceType<typeof IonMenu>>();

	function closeMenu() {
		menu.value.$el.close();
	}

	function showNotePopup() {
		showAddNote();
	}

	function showTaskRequestPopup() {
		showAddEditTaskRequest(getCurrentInstance());
	}

	async function registerDevice() {
		let loading = await loadingController.create({
			message: t("menu.registering-device"),
		});
		loading.present();
		try {
			let attestationOptions = await webauthn.getAttestationOptions();
			let credentialOptions =
				webauthn.credentialCreationOptions(attestationOptions);
			let credentials =
				await webauthn.createCredentials(credentialOptions);
			let attestation = webauthn.attestationRequest(credentials);
			let attestationResponse =
				await webauthn.attestIdentity(attestation);
			if (attestationResponse.data.status == "ok") {
				sendMessage(t("menu.device-registered"), 5000);
			} else throw "Failed to register device";
		} catch (e) {
			console.error("Failed to register device", e);
			sendMessage(t("menu.failed-register"), 5000, null, "danger");
		} finally {
			loading.dismiss();
		}
	}

	async function _showInstallPossibleModal() {
		closeMenu();
		await showInstallPossibleModal(false);
	}

	// Disabled for now: code was in a non-functioning state. See FIXME above.
	// const desktopSwitchEnabled = computed(() => {
	// 	// if we decide to disable this for smaller screens, it can be done here.
	// 	// if (screen.width < 500) return false;
	// 	return store.state.customerPreferences.sitewide?.showMobileSwitch || false;
	// });
</script>
