import router from "@/router";
import store from "@/store";
import { seedgreenAPI } from "seedgreen-shared/api/axios";
import { mobileQueryClient } from "@/lib/query-client";
import { resetOnLogout } from "@/stores/clearState";
import { captureException } from "@sentry/vue";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/vue";

export function logout() {
	_logout().finally(() => router.push({ name: "login" }));
}

async function _logout() {
	try {
		await store.dispatch("logout");
		await resetOnLogout();

		if (navigator.onLine) {
			try {
				await seedgreenAPI.post("User/Logoff", false);
			} catch (error) {
				// Ignore 401 errors - user is already logged out
				if ((error as AxiosError).status != 401) {
					console.error("Error logging out:", error);
					Sentry.captureException(error);
				}
			}
		}

		await mobileQueryClient.invalidateQueries({
			queryKey: ["verify-token"],
		});
		await mobileQueryClient.invalidateQueries();
	} catch (e) {
		captureException(e);
		throw e;
	}
}
